import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LandingPage from './components/LandingPage';
import AdminDashboard from './components/AdminDashboard';
import PaymentComponent from './components/PaymentComponent';
import VideoComponent from './components/VideoComponent';

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/adminsecretbuynow" element={<AdminDashboard />} />
        <Route path="/pay/:videoId" element={<PaymentComponent />} />
        <Route path="/video/:videoId" element={<VideoComponent />} />
      </Routes>
    </Router>
  );
};

export default App;