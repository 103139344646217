import React, { useState, useEffect } from 'react';
import './VideoComponent.css';
import { useParams } from 'react-router-dom';

const VideoComponent = () => {
  const [videoLink, setVideoLink] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const { videoId } = useParams();

  useEffect(() => {
    const fetchVideo = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/video/${videoId}`);
        if (response.ok) {
          const data = await response.json();
          setVideoLink(data.videoLink);
        } else {
          const errorData = await response.json();
          setError(errorData.error.message);
        }
      } catch (error) {
        setError('An error occurred while fetching the video.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchVideo();
  }, [videoId]);

  if (isLoading) {
    return <div className="loading">Loading video...</div>;
  }

  if (error) {
    return <div className="error">{error}</div>;
  }

  return (
    <div className="video-container">
      <h1>Your Aerial View Video</h1>
      <div className="video-wrapper">
        <video src={videoLink} controls>
          Your browser does not support the video tag.
        </video>
        <div className="download-instruction">
          <p>To download on desktop: Click the three dots in the video player and select "Download"</p>
          <svg className="arrow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
            <line x1="5" y1="12" x2="19" y2="12"></line>
            <polyline points="12 5 19 12 12 19"></polyline>
          </svg>
        </div>
      </div>
      <div className="download-options">
        <a href={videoLink} download="aerial_view_video.mp4" className="download-button">
          Download MP4
        </a>
        <p className="download-tip">Tip: Right-click and choose "Save link as..." for more download options</p>
      </div>
      <div className="additional-info">
        <h2>How to Download Your Video</h2>
        <ul>
          <li>On Desktop: Use the download button above or the video player's download option</li>
          <li>On Mobile: Tap and hold the "Download MP4" button, then select "Download link" or "Save link"</li>
          <li>Having trouble? Try right-clicking (or long-pressing on mobile) the video and selecting "Save video as..."</li>
        </ul>
      </div>
    </div>
  );
};

export default VideoComponent;