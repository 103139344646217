import React, { useState } from 'react';
import AddressAutocomplete from './AddressAutocomplete';
import './LandingPage.css';

const LandingPage = () => {
  const [email, setEmail] = useState('');
  const [address, setAddress] = useState('');
  const [status, setStatus] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleAddressSelect = (selectedAddress) => {
    setAddress(selectedAddress);
  };

  const requestVideo = async () => {
    if (!email.trim() || !address.trim()) {
      setError('Please enter both email and address');
      return;
    }

    setError('');
    setStatus('Requesting video generation...');
    setIsLoading(true);

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/request-video`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: email.trim(), address: address.trim() }),
      });

      const data = await response.json();

      if (response.ok) {
        setStatus(data.message);
      } else {
        setError(`Error: ${data.error ? data.error.message : 'Unknown error'}`);
      }
    } catch (error) {
      setError(`Error: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="landing-container">
      <header className="hero">
        <h1>Elevate Your Real Estate Listings with 3D Aerial Views</h1>
        <p>Captivate potential buyers with stunning, immersive property tours</p>
      </header>

      <section className="features">
        <div className="feature-text">
          <h2>Transform Your Listings</h2>
          <p>Our cutting-edge 3D Aerial View technology brings properties to life, allowing potential buyers to explore from every angle. Stand out in a crowded market and close deals faster.</p>
          <ul>
            <li>Showcase property surroundings</li>
            <li>Highlight unique features</li>
            <li>Increase engagement and interest</li>
          </ul>
        </div>
        <div className="feature-video">
          <video controls>
            <source src="/sample-aerial-view.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </section>

      <section className="pricing">
        <h2>Affordable Pricing</h2>
        <div className="price-card">
          <h3>3D Aerial View</h3>
          <div className="price">$3.00 <span>per video</span></div>
          <ul>
            <li>High-quality 3D rendering</li>
            <li>Immersive property tour</li>
            <li>Easy to share and embed</li>
            <li>Downloadable MP4 file</li>
          </ul>
        </div>
      </section>

      <section className="cta">
        <h2>Generate Your 3D Aerial View</h2>
        <p>Experience the power of 3D visualization for your listings</p>
        <form onSubmit={(e) => { e.preventDefault(); requestVideo(); }}>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter your email"
            required
          />
          <AddressAutocomplete onAddressSelect={handleAddressSelect} />
          <button type="submit" disabled={isLoading}>
            {isLoading ? 'Processing...' : 'Generate 3D View for $3.00'}
          </button>
        </form>
        {status && <p className="status">{status}</p>}
        {error && <p className="error">{error}</p>}
      </section>

      <section className="benefits">
        <h2>Why Choose Our 3D Aerial Views?</h2>
        <div className="benefit-cards">
          <div className="benefit-card">
            <h3>Stunning Visuals</h3>
            <p>Showcase properties in breathtaking detail with high-quality 3D renderings.</p>
          </div>
          <div className="benefit-card">
            <h3>Easy to Use</h3>
            <p>Generate 3D views with just an address - no technical expertise required.</p>
          </div>
          <div className="benefit-card">
            <h3>Boost Engagement</h3>
            <p>Captivate potential buyers and increase interest in your listings.</p>
          </div>
        </div>
      </section>

      <section className="final-cta">
        <h2>Ready to Transform Your Real Estate Business?</h2>
        <p>Join the ranks of successful agents using 3D Aerial Views to close more deals.</p>
        <button onClick={() => document.querySelector('input[type="email"]').focus()}>
          Get Started Now
        </button>
      </section>

      <footer>
        <p>© 2024 3D Aerial View Generator. All rights reserved.</p>
      </footer>
    </div>
  );
};

export default LandingPage;