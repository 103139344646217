import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

const PaymentComponent = () => {
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const { videoId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const initiatePayment = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/pay`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ videoId }),
        });

        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.error.message || 'Failed to initiate payment');
        }

        const { checkoutUrl } = await response.json();
        window.location.href = checkoutUrl; // Redirect to Stripe Checkout
      } catch (error) {
        setError(error.message);
      } finally {
        setIsLoading(false);
      }
    };

    initiatePayment();
  }, [videoId]);

  if (isLoading) {
    return <div>Preparing payment session...</div>;
  }

  if (error) {
    return (
      <div>
        <h2>Error</h2>
        <p>{error}</p>
        <button onClick={() => navigate('/')}>Return to Home</button>
      </div>
    );
  }

  return <div>Redirecting to payment...</div>;
};

export default PaymentComponent;