import React, { useState, useEffect } from 'react';
import './AdminDashboard.css';

const AdminDashboard = () => {
  const [videos, setVideos] = useState([]);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchVideos = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/admin/videos`);
        if (response.ok) {
          const data = await response.json();
          setVideos(data);
        } else {
          const errorData = await response.json();
          setError(`Failed to fetch video data: ${errorData.error?.message || 'Unknown error'}`);
        }
      } catch (error) {
        setError('Error: ' + error.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchVideos();
  }, []);

  const getStatusDisplay = (status) => {
    switch(status) {
      case 'ACTIVE': return 'Ready';
      case 'PROCESSING': return 'Processing';
      case 'LONG_PROCESSING': return 'Long Processing';
      case 'NO_IMAGERY_FINAL': return 'No 3D Imagery Available';
      case 'ERROR': return 'Error';
      default: return status;
    }
  };

  const getStatusClass = (status) => {
    switch(status) {
      case 'ACTIVE': return 'status-ready';
      case 'PROCESSING': return 'status-processing';
      case 'LONG_PROCESSING': return 'status-long-processing';
      case 'NO_IMAGERY_FINAL': return 'status-no-imagery';
      case 'ERROR': return 'status-error';
      default: return '';
    }
  };

  if (isLoading) {
    return <div className="loading">Loading...</div>;
  }

  if (error) {
    return <div className="error">Error: {error}</div>;
  }

  return (
    <div className="admin-dashboard">
      <h1>Admin Dashboard</h1>
      {videos.length === 0 ? (
        <p>No videos found.</p>
      ) : (
        <table>
          <thead>
            <tr>
              <th>Video ID</th>
              <th>Email</th>
              <th>Address</th>
              <th>Status</th>
              <th>Timestamp</th>
              <th>Paid</th>
              <th>Video Link</th>
            </tr>
          </thead>
          <tbody>
            {videos.map((video) => (
              <tr key={video.id}>
                <td>{video.id}</td>
                <td>{video.email}</td>
                <td>{video.address}</td>
                <td className={getStatusClass(video.state)}>{getStatusDisplay(video.state)}</td>
                <td>{new Date(video.timestamp).toLocaleString()}</td>
                <td>{video.paid ? 'Yes' : 'No'}</td>
                <td>
                  {video.videoLink ? (
                    <a href={video.videoLink} target="_blank" rel="noopener noreferrer">
                      View Video
                    </a>
                  ) : (
                    'N/A'
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default AdminDashboard;