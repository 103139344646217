import React, { useRef, useEffect } from 'react';

const AddressAutocomplete = ({ onAddressSelect, disabled }) => {
  const inputRef = useRef(null);
  const autocompleteRef = useRef(null);

  useEffect(() => {
    if (window.google && window.google.maps && window.google.maps.places) {
      autocompleteRef.current = new window.google.maps.places.Autocomplete(inputRef.current, {
        types: ['address'],
        componentRestrictions: { country: 'us' },
        fields: ['formatted_address'],
      });

      autocompleteRef.current.addListener('place_changed', () => {
        const place = autocompleteRef.current.getPlace();
        if (place.formatted_address) {
          onAddressSelect(place.formatted_address);
        }
      });
    }
  }, [onAddressSelect]);

  return (
    <input
      ref={inputRef}
      type="text"
      placeholder="Enter address"
      disabled={disabled}
      style={{
        width: '100%',
        padding: '0.5rem',
        border: '1px solid #ccc',
        borderRadius: '0.25rem',
        marginBottom: '1rem'
      }}
    />
  );
};

export default AddressAutocomplete;
